<template lang="pug">
.col-start-1.col-end-3.my-4.overflow-y-auto.overflow-x-hidden
  table.w-full.table-fixed
    thead.text-left
      tr.border-b.border-reallylightblue
        th.bg-white.top-0.sticky
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ isNewSensor ? $t("objects_tabs.sensors.sensors_create") : $t("name") }}
        th.bg-white.top-0.sticky.truncate.w-36
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
            v-if='!isNewSensor'
          ) {{ $t("type") }}
        th.bg-white.top-0.sticky.w-32
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
            v-if='!isNewSensor'
          ) {{ $t("objects_tabs.sensors.option1") }}
        th.w-6.text-center
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
            :class='isNewSensor ? "rotate-45" : "rotate-0"',
            width='16',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='createSensor'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
            )
      tr(v-if='isNewSensor')
        th.bg-white(:colspan='4')
          .flex.items-end.my-3
            .flex.flex-col(class='w-2/3')
              .flex.flex-col.relative
                span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("name") }}
                el-input.mt-3.w-56(
                  type='text',
                  v-model='newSensor.name',
                  :class='{ "input-error": isSensorNameMissing }'
                )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isSensorNameMissing'
                  ) {{ $t("objects.name") }}
              .flex.flex-col.relative
                span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.type_sensor") }}
                el-select.mt-3.w-56(
                  v-model='newSensor.type',
                  :class='{ "input-error": isSensorTypeMissing }'
                )
                  el-option(
                    v-for='item in sensor_type',
                    :key='item.key',
                    :label='item.value',
                    :value='item'
                  )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isSensorTypeMissing'
                  ) {{ $t("objects_tabs.sensors.error_type_sensor") }}
              .flex.flex-col.relative
                span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.option1") }}
                .flex.mt-3.items-center.justify-between
                  el-select.w-56(
                    v-model='newSensor.param1.param_key',
                    clearable,
                    :class='{ "input-error": isSensorParamMissing }'
                  )
                    el-option(
                      v-for='item in terminalParams',
                      :key='item.value',
                      :label='item.label',
                      :value='item.value'
                    )
                  svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
                    width='18',
                    height='18',
                    fill='none',
                    xmlns='http://www.w3.org/2000/svg',
                    @click='$emit("param1", JSON.parse(JSON.stringify(newSensor.param1)))'
                  )
                    path(
                      d='M5.952 17.991a2.025 2.025 0 01-1.215-.413l-1.863-1.386a2.132 2.132 0 01-.44-2.896 1.853 1.853 0 00.206-1.673l-.054-.144A1.646 1.646 0 001.58 10.38h-.144A2.106 2.106 0 01.103 7.736L.84 5.397A1.96 1.96 0 012.593 3.92c.26-.018.521.016.767.101a1.737 1.737 0 001.602-.26l.117-.09a1.745 1.745 0 00.657-1.36v-.215A2.087 2.087 0 017.796 0h2.295a2.035 2.035 0 011.44.603c.396.403.617.946.612 1.511v.252a1.583 1.583 0 00.621 1.286l.1.072a1.566 1.566 0 001.43.234l.306-.099a2.035 2.035 0 012.592 1.359l.71 2.266a2.122 2.122 0 01-1.313 2.636l-.18.063a1.701 1.701 0 00-1.107 1.152 1.796 1.796 0 00.225 1.484l.234.342a2.14 2.14 0 01-.45 2.905l-1.809 1.395a2.018 2.018 0 01-2.898-.477l-.107-.153a1.575 1.575 0 00-1.35-.702 1.62 1.62 0 00-1.287.693l-.207.297a2.024 2.024 0 01-1.35.872 1.79 1.79 0 01-.351 0zM2.164 8.654a3.447 3.447 0 012.141 2.249v.108a3.597 3.597 0 01-.414 3.256.342.342 0 000 .459l1.935 1.466a.225.225 0 00.333-.063l.207-.297a3.393 3.393 0 015.58 0l.107.162a.27.27 0 00.162.108.223.223 0 00.171-.045l1.854-1.403a.324.324 0 00.063-.44l-.234-.343a3.597 3.597 0 01-.477-3.076 3.525 3.525 0 012.24-2.348l.18-.063a.306.306 0 00.172-.396l-.702-2.24a.315.315 0 00-.18-.17.19.19 0 00-.171 0l-.306.098a3.367 3.367 0 01-3.087-.512l-.036-.081a3.383 3.383 0 01-1.34-2.7v-.278a.333.333 0 00-.09-.234.28.28 0 00-.19-.072H7.797a.279.279 0 00-.261.297v.225A3.508 3.508 0 016.168 5.1l-.117.09a3.52 3.52 0 01-3.267.53.198.198 0 00-.126 0 .252.252 0 00-.108.135l-.746 2.348a.324.324 0 00.198.405l.162.045z'
                    )
                    path(
                      d='M9.002 12.144a3.15 3.15 0 01-3.089-3.763 3.148 3.148 0 015.708-1.135 3.148 3.148 0 01-2.619 4.898zm0-4.498a1.35 1.35 0 100 2.7 1.35 1.35 0 000-2.7z'
                    )

                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isSensorParamMissing'
                  ) {{ $t("objects_tabs.sensors.error_param12") }}
              .flex.flex-col.relative
                span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.option2") }}
                .flex.mt-3.items-center.justify-between
                  el-select.w-56(
                    v-model='newSensor.param2.param_key',
                    clearable,
                    :class='{ "input-error": isSensorParamMissing }'
                  )
                    el-option(
                      v-for='item in terminalParams',
                      :key='item.value',
                      :label='item.label',
                      :value='item.value'
                    )
                  svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
                    width='18',
                    height='18',
                    fill='none',
                    xmlns='http://www.w3.org/2000/svg',
                    @click='$emit("param2", JSON.parse(JSON.stringify(newSensor.param2)))'
                  )
                    path(
                      d='M5.952 17.991a2.025 2.025 0 01-1.215-.413l-1.863-1.386a2.132 2.132 0 01-.44-2.896 1.853 1.853 0 00.206-1.673l-.054-.144A1.646 1.646 0 001.58 10.38h-.144A2.106 2.106 0 01.103 7.736L.84 5.397A1.96 1.96 0 012.593 3.92c.26-.018.521.016.767.101a1.737 1.737 0 001.602-.26l.117-.09a1.745 1.745 0 00.657-1.36v-.215A2.087 2.087 0 017.796 0h2.295a2.035 2.035 0 011.44.603c.396.403.617.946.612 1.511v.252a1.583 1.583 0 00.621 1.286l.1.072a1.566 1.566 0 001.43.234l.306-.099a2.035 2.035 0 012.592 1.359l.71 2.266a2.122 2.122 0 01-1.313 2.636l-.18.063a1.701 1.701 0 00-1.107 1.152 1.796 1.796 0 00.225 1.484l.234.342a2.14 2.14 0 01-.45 2.905l-1.809 1.395a2.018 2.018 0 01-2.898-.477l-.107-.153a1.575 1.575 0 00-1.35-.702 1.62 1.62 0 00-1.287.693l-.207.297a2.024 2.024 0 01-1.35.872 1.79 1.79 0 01-.351 0zM2.164 8.654a3.447 3.447 0 012.141 2.249v.108a3.597 3.597 0 01-.414 3.256.342.342 0 000 .459l1.935 1.466a.225.225 0 00.333-.063l.207-.297a3.393 3.393 0 015.58 0l.107.162a.27.27 0 00.162.108.223.223 0 00.171-.045l1.854-1.403a.324.324 0 00.063-.44l-.234-.343a3.597 3.597 0 01-.477-3.076 3.525 3.525 0 012.24-2.348l.18-.063a.306.306 0 00.172-.396l-.702-2.24a.315.315 0 00-.18-.17.19.19 0 00-.171 0l-.306.098a3.367 3.367 0 01-3.087-.512l-.036-.081a3.383 3.383 0 01-1.34-2.7v-.278a.333.333 0 00-.09-.234.28.28 0 00-.19-.072H7.797a.279.279 0 00-.261.297v.225A3.508 3.508 0 016.168 5.1l-.117.09a3.52 3.52 0 01-3.267.53.198.198 0 00-.126 0 .252.252 0 00-.108.135l-.746 2.348a.324.324 0 00.198.405l.162.045z'
                    )
                    path(
                      d='M9.002 12.144a3.15 3.15 0 01-3.089-3.763 3.148 3.148 0 015.708-1.135 3.148 3.148 0 01-2.619 4.898zm0-4.498a1.35 1.35 0 100 2.7 1.35 1.35 0 000-2.7z'
                    )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isSensorParamMissing'
                  ) {{ $t("objects_tabs.sensors.error_param12") }}
              .flex.flex-col.relative
                span.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.sensors.formula") }}
                el-select.mt-3.w-56(
                  v-model='newSensor.formula',
                  clearable,
                  :class='{ "input-error": isSensorFormulaMissing }'
                )
                  el-option(
                    v-for='item in formula',
                    :key='item.key',
                    :label='item.value',
                    :value='item.key'
                  )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isSensorFormulaMissing'
                  ) {{ $t("objects_tabs.sensors.error_formula") }}
              skif-checkbox.mt-6(
                :label='$t("objects_tabs.sensors.hide_in_reports")',
                v-model='newSensor.hide_in_reports'
              )
            .flex.justify-end(class='w-1/3')
              skif-button(width='28', @click='createOrUpdateSensor') {{ $t("btn.save") }}
    tbody(v-if='!isNewSensor')
      tr.cursor-pointer.hover_bg-hoverrow(
        v-for='sensor in filterDataSensors',
        :key='sensor.id',
        @click='editSensor(sensor, { isUpdate: true })'
      )
        td.truncate.pr-2.text-darkblue
          el-tooltip(
            :open-delay='700',
            effect='dark',
            :content='sensor.name',
            placement='bottom',
            :offset='0',
            :visible-arrow='false'
          )
            span.font-bold.leading-10.text-sm {{ sensor.name }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ sensor.type.value }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ sensor.param1.param_key }}
        td.flex.items-center.justify-center
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
            width='12',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='(event) => { event.stopPropagation(); deleteSensor(sensor.id); }'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
            )
</template>

<script>
import sensors from '../mixins/sensors'
export default {
  mixins: [sensors],
  props: ['unit', 'filterStringSensors'],
  data() {
    return {}
  }
}
</script>

<style></style>
